.box-img {
	display: flex;
	align-items: center;
	overflow: hidden;
	width: 100%;
	margin-top: 20px;
}

.img-article {
	height: 100%;
	width: 100%;
}

.title-section-article {
	margin-bottom: 0;
}

.link-article {
	margin: 10px 0;
	font-size: 18px;
	color: #222;
	text-decoration: none;
	transition: color 0.4s;
}

.link-article:hover {
	color: yellowgreen;
}

.box-article > p,
.box-article > pre,
.title-article {
	margin: 10px 0;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
	.box-img {
		max-height: 350px;
	}
}
@media (min-width: 992px) {
	.box-img {
		max-height: 500px;
	}
}
