.box-form-registration,
.box-form-login {
	display: flex;
	flex-direction: column;
}

.btn-form {
	display: block;
	margin: 5px 0;
	margin-left: auto;
	padding: 8px 10px;
	font-size: 20px;
	font-weight: bold;
	text-transform: uppercase;
	border: none;
	color: white;
	background-color: yellowgreen;
	cursor: pointer;
	transition: background-color 0.4s;
}

.btn-form-registration,
.btn-form-login {
	width: 100%;
}

.btn-form:hover {
	background-color: rgb(131, 175, 42);
}

.custom-file-input {
	margin: 6px 0;
	width: 100%;
	font-family: Roboto;
	font-size: 16px;
	border: none;
	background-color: #ececec;
}

.custom-file-input::-webkit-file-upload-button {
	display: none;
}
.custom-file-input::before {
	content: "Prześlij";
	display: inline-block;
	margin-right: 10px;
	border: 1px solid #999;
	cursor: pointer;
	text-transform: uppercase;
	padding: 8px;
	border: none;
	color: white;
	background-color: yellowgreen;
	cursor: pointer;
	transition: background-color 0.4s;
}
.custom-file-input:hover::before {
	background-color: rgb(139, 188, 42);
}

.form-error {
	margin-top: -3px;
	margin-bottom: 5px;
	font-size: 12px;
	text-align: center;
	color: tomato;
	animation: animation-error 0.4s;
}

.password-recovery {
	text-decoration: none;
	color: #222;
}

.password-recovery:hover {
	color: yellowgreen;
}

@keyframes animation-error {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	20% {
		opacity: 1;
	}
	80% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}

.box-contact-email-tel {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}
.box-contact-email-tel > div {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.form-input::placeholder,
.form-textarea::placeholder {
	font-size: 16px;
	font-family: Roboto;
}

.form-input,
.form-textarea {
	margin: 6px 0;
	padding: 8px;
	font-family: Roboto;
	font-size: 16px;
	border: 1px solid #d0d0d0;
	background-color: #ececec;
}

.form-success {
	font-size: 20px;
	text-align: center;
	color: rgb(43, 43, 43);
}

.form-textarea {
	resize: vertical;
	width: 100%;
}

@media (min-width: 457px) {
	.box-form-registration,
	.box-form-contact,
	.box-form-login {
		margin-left: auto;
		margin-right: auto;
	}
	.form-input-email {
		margin-right: 3px;
	}
	.form-input-tel {
		margin-left: 3px;
	}
	.box-form-registration,
	.box-form-login {
		max-width: 350px;
	}
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
	.box-form-contact {
		width: 70%;
	}
}
