.box-comments {
	margin-bottom: 20px;
	padding: 10px;
	background-color: #f6f6f6;
}

.box-replies {
	margin-left: 30px;
}

.box-comment {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.comment {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: flex-start;
	flex-grow: 1;
	padding: 10px;
	margin: 5px 0;
	background-color: #ececec;
}

.btn-delete-comment {
	margin-top: 3px;
	padding: 2px 5px;
	font-size: 14px;
	color: white;
	background-color: yellowgreen;
}

.comment-author,
.comment-date {
	font-size: 12px;
}

.comment-content {
	margin: 3px 0;
}

.btn-comment {
	font-size: 12px;
}
