.box-popup-edit {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #000000ac;
	z-index: 9999;
	overflow-y: auto;
}

.box-popup-edit > div {
	width: 100%;
	max-width: 600px; /* Możesz dostosować szerokość pop-upa tutaj */
	background-color: #ffffff;
	flex-direction: column;
	justify-content: center;
	padding: 20px 15px;
}

.box-popup-edit h2 {
	text-align: center;
	margin-bottom: 10px;
	text-transform: uppercase;
}

.box-form-edit-article {
	display: flex;
	flex-direction: column;
}

.img-edit-article {
	width: 150px;
}

.box-btn-edit-popup {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.btn-edit-popup {
	display: inline;
	font-size: 16px;
	width: 40%;
	margin: 5px;
}

@media (min-width: 576px) {
	.box-popup-edit > div {
		width: 80%;
		padding: 20px;
	}
	.img-edit-article {
		width: 200px;
	}
}
@media (min-width: 768px) {
	.box-popup-edit > div {
		width: 80%;
	}
}
@media (min-width: 992px) {
	.box-popup-edit > div {
		width: 70%;
	}
}
