.preloader:before,
.preloader:after {
	position: absolute;
	content: "";
}

.preloader:before {
	width: 52px;
	height: 102px;
	background: #fff;
	border-radius: 50% 0 0 50%;
	top: -1px;
	left: -1px;
	transform-origin: 52px 51px;
	animation: animation 1.6s infinite ease 1.2s;
}

.preloader {
	margin: 40px auto;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	box-shadow: inset 0 0 0 13px yellowgreen;
	transform: translateZ(0);
}

.preloader:after {
	width: 52px;
	height: 102px;
	background: #fff;
	border-radius: 0 50% 50% 0;
	top: -1px;
	left: 51px;
	transform-origin: 0px 51px;
	animation: animation 1.6s infinite ease;
}

.preloader > p {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	font-size: 14px;
	z-index: 1;
}

@keyframes animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
