.box-article {
	margin: 25px 0;
}

.link-blog {
	display: block;
	margin: 10px 0;
	font-size: 18px;
	color: #222;
	text-decoration: none;
	transition: color 0.4s;
}

.link-blog:hover {
	color: yellowgreen;
}
