.app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: #fff;
}

.header {
	z-index: 1;
}

.aside {
	position: sticky;
	top: 0;
	background-color: #333;
}

.main {
	flex-grow: 1;
}

.section {
	flex-grow: 1;
	padding: 20px;
}

.footer {
	padding: 10px;
	text-align: center;
	background-color: #222;
	color: white;
}

.title-section {
	margin-bottom: 30px;
	font-size: 40px;
	border-bottom: 5px solid yellowgreen;
}


select,
button {
	font-size: 16px;
	padding: 8px;
	border: none;
	background-color: #ececec;
	cursor: pointer;
}

input {
	font-size: 16px;
	align-items: center;
	border: none;
	background-color: #ececec;
}

label{
	cursor: pointer;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
	.main {
		display: flex;
	}
	.page {
		flex-grow: 1;
	}
}
@media (min-width: 992px) {
}
