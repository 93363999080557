.box-popup-delete {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	background-color: #000000ac;
	z-index: 1;
}
.box-popup-delete > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 200px;
	width: 100%;
	background-color: #1a1a1a;
}

.box-popup-delete h2 {
	margin-bottom: 10px;
	color: white;
	text-transform: uppercase;
}

.box-popup-delete > div > div {
	width: 100%;
	text-align: center;
}

.box-popup-delete button {
	display: inline;
	min-width: 120px;
	margin: 5px;
}

@media (min-width: 576px) {
	.box-popup-delete > div {
		width: 80%;
	}
}
@media (min-width: 768px) {
	.box-popup-delete > div {
		width: 70%;
	}
}
@media (min-width: 992px) {
	.box-popup-delete > div {
		width: 50%;
	}
}
