.box-pagination {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 15px 0;
}

.btn-pagination {
	padding: 8px 10px;
	font-weight: bold;
	text-decoration: none;
	text-transform: uppercase;
	color: white;
	background-color: yellowgreen;
	border: none;
	cursor: pointer;
	transition: background-color 0.6s;
}

.btn-pagination:hover {
	background-color: rgb(137, 184, 44);
}

.btn-pagination-disabled {
	color: #333;
	background-color: #ececec;
	pointer-events: none;
}
.btn-pagination-disabled:hover {
	color: #333;
	background-color: #ececec;
}
